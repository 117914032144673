.pages-apidays,
.pages-php_stoke {
  @apply relative;

  background:
      url("../../images/misc/noise.png"),
      radial-gradient(22.01% 27.17% at 95.1% 15.89%, #4C50BB 0%, rgba(2, 95, 215, 0) 100%),
      radial-gradient(50% 52.52% at 50% 37.73%, rgba(74, 20, 75, 0.5) 0%, rgba(2, 95, 215, 0) 100%),
      radial-gradient(37.35% 25.79% at 47.43% 25.79%, rgba(255, 133, 133, 0.8) 0%, rgba(2, 95, 215, 0) 100%);

  &::before {
    @apply absolute bg-white h-full inset-0 opacity-80 w-full;
    content: "";
    z-index: -1;
  }

  nav {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
