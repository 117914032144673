subscription-details {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--title-color);
    font-weight: 600;
    padding: var(--spacing-3) var(--spacing-6);
    border-bottom: var(--border-default);
  }

  .title {
    color: var(--title-color);
    padding-block-end: var(--spacing-3);
    font-weight: 500;
  }

  .current-quotas {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  .current-features {
    margin-block: var(--spacing-6) var(--spacing-6);

    .features-header {
      color: var(--title-color);
      padding-block-end: var(--spacing-3);
      font-weight: 500;
    }

    .features-container {
      column-count : auto;
      column-width : 13rem;
      column-gap: var(--spacing-2);
      padding: 0;
    }

    .feature-content {
      display: flex;
      break-inside: avoid;
      padding-block: 0 var(--spacing-1);
      font-weight: 500;

      > svg {
        margin-right: var(--spacing-1);
      }
    }
  }
}
