.input-group {
  align-items: stretch;
  display: flex;
}

.input-group input[type="text"] {
  flex-grow: 1;
}

.input-group__extra {
  align-items: center;
  display: inline-flex;
  flex-shrink: 1;
  gap: var(--spacing-3);
  padding: var(--spacing-1) var(--spacing-4);
}

.input-group__extra .button {
  height: revert;
  padding: var(--spacing-1) var(--spacing-3);
}
