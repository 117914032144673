.blog_posts-show {
  article {
    main {
      @apply text-lg;

      a {
        @apply text-blue;
      }

      h2 {
        @apply mt-10 text-3xl;
      }

      > h3 {
        @apply font-bold mt-8 text-black text-2xl;
      }

      h4 {
        @apply mt-8 text-black text-lg font-bold;
      }

      img,
      video {
        @apply mt-10 rounded-lg w-full;
      }

      > p:not(:first-of-type) {
        @apply mt-6;
      }

      strong {
        @apply font-semibold text-black;
      }

      > ul,
      ol {
        @apply mt-8;

        li:not(:first-child) {
          @apply mt-4;
        }

        ul,
        ol {
          @apply ml-8 mt-4;
        }
      }

      ol {
        @apply list-decimal pl-8;

        li {
          &::marker {
            @apply text-blue;
          }
        }
      }

      > ul {
        li {
          &::before {
            @apply h-2 w-2 bg-gradient-blue inline-block mr-4 rounded-full;
            content: "";
          }
        }
      }

      li {
        p {
          display: inline;
        }
      }

      blockquote {
        @apply pl-20 pb-6 mt-16 font-semibold relative;

        &::before {
          @apply block absolute;
          content: "";
          background: url("../../images/misc/blockquote.svg");
          height: 20px;
          width: 22px;
          top: 4px;
          left: 40px;
        }
      }

      .highlight {
        @apply bg-black pt-0 px-2 rounded-lg;
      }

      code {
        @apply break-all inline-block text-sm bg-lightblue text-blue;
      }

      p {
        code:not(:first-of-type) {
          @apply -mt-8;
        }
      }

      pre {
        @apply inline-grid mt-8 w-full;

        code {
          @apply block bg-black px-4 py-2 text-gray;

          overflow: auto;
        }
      }
    }

    aside {
      .bg-gradient-blue-to-purple {
        background:
          radial-gradient(95% 186.79% at 100% 83.23%, rgba(134, 63, 207, 0.8) 0%, rgba(2, 95, 215, 0) 100%),
          radial-gradient(84.11% 84.11% at 4.9% 15.89%, #0445AA 0%, rgba(2, 95, 215, 0) 100%),
          #025FD7;
      }
    }
  }
}
