.workflow {
  @apply bg-lightgray;

  .workflow-container {
    @apply px-8 py-20 max-w-lg-screen mx-auto;

    .header {
      @apply mx-auto;

      .description {
        @apply mt-6 text-lg;
      }
    }

    .workflow-elements {
      @apply grid grid-cols-1 gap-16 mt-20 text-black;

      .workflow-element {
        .element-image {
          @apply flex;
        }

        .title {
          @apply font-semibold my-4 text-lg;
        }

        a {
          @apply mt-4 inline-flex items-center;
        }
      }
    }
  }
}

@screen md {
  .workflow {
    @apply text-center;

    .workflow-container {
      @apply py-20;

      .workflow-elements {
        @apply grid-cols-2;

        .workflow-element {
          .element-image {
            @apply justify-center mx-auto;
          }
        }
      }
    }
  }
}

@screen lg {
  .workflow {
    .workflow-container {
      @apply px-20;

      .header {
        @apply max-w-130;
      }

      .workflow-element {
        @apply lg:max-w-[320px];
      }
    }
  }
}

@screen xl {
  .workflow {
    .workflow-container {
      @apply px-30;

      .workflow-elements {
        @apply grid-cols-3;
      }
    }
  }
}
