.hero {
  .image-container {
    @apply relative w-full max-w-72 min-h-140 hidden;

    &::before {
      content: "";

      @apply absolute bg-contain w-full;
      top: 40px;
      height: 622px;
      background: url("../../images/misc/dots-hero.svg");
      z-index: -1;
    }

    img {
      @apply w-full;
    }
  }
}

.feature-strate {
  @apply mt-0 px-8 py-12 grid items-center justify-between max-w-lg-screen mx-auto;

  .text {
    @apply w-full;

    p {
      @apply my-8 text-lg dark:text-slate-40;
    }
  }

  .image-container {
    @apply relative w-full row-start-2 mt-10;

    &::before {
      content: "";
      @apply absolute transform-center w-full;
      height: 500px;
      background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(255, 255, 255, 0) 0%,
          var(--white) 100%
        ),
        linear-gradient(90deg, var(--sky-blue) 0.3%, var(--blue) 102.11%);
      opacity: 0.16;
      z-index: -1;
    }
  }
}

@screen md {
  .hero {
    .image-container {
      @apply block max-w-140 mt-0;
    }
  }

  .feature-strate {
    @apply flex;

    .text {
      @apply w-112;
    }

    .image-container {
      @apply mt-0;
    }

    &:nth-child(even) {
      .image-container {
        @apply ml-20;

        img {
          @apply -mr-6;
        }
      }
    }

    &:nth-child(odd) {
      .image-container {
        @apply mr-20;

        img {
          @apply -ml-6;
        }
      }
    }
  }
}

@screen lg {
  .hero {
    .image-container {
      @apply max-w-150;

      &::before {
        width: 520px;
      }
    }
  }

  .feature-strate {
    @apply px-20 mt-10;

    .text {
      @apply w-5/12;
    }
  }
}

@screen xl {
  .feature-strate {
    @apply px-30 mt-30;

    .text {
      @apply w-112;
    }
  }
}
