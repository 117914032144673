input[type='text'],
input[type='email'],
input[type='password'],
input[type='url'],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  @apply rounded;
  width: 100%;
}

input {
  height: var(--spacing-8);
}

input[type='number'], 
input[type='datetime-local'] {
  width: 50%;
}

@media (max-width: 500px) {
    input[type='number'], 
    input[type='datetime-local'] {
        width: 100%;
    }
}
  
