.alert {
  --alert-bg-color: var(--panel-bg-color);
  --alert-border-color: var(--panel-bg-color);

  @apply flex flex-col items-start;
  background-color: var(--alert-bg-color);
  border: 1px solid rgba(var(--alert-border-color), 20%);
  border-radius: var(--border-radius-medium);
  margin-bottom: var(--spacing-3);
  padding: var(--spacing-3);
  padding-left: var(--spacing-12);
  position: relative;
  row-gap: var(--spacing-3);
}

.alert::before {
  background: var(--alert-icon-path);
  content: "";
  display: inline-block;
  height: var(--spacing-4);
  left: var(--spacing-4);
  min-width: var(--spacing-4);
  position: absolute;
  top: calc(var(--spacing-3) + var(--spacing-05));
  width: var(--spacing-4);
}

.alert p {
  margin: 0;
}

.alert.no-icon {
  padding-left: var(--spacing-3);

  &::before {
    content: none;
  }
}

.alert-info {
  --alert-bg-color: var(--alert-background-info);
  --alert-border-color: var(--alert-border-color-info);
  --alert-icon-path: url(../../images/icons/info.svg);
}

.alert-success {
  --alert-bg-color: var(--alert-background-success);
  --alert-border-color: var(--alert-border-color-success);
  --alert-icon-path: url(../../images/icons/success.svg);
}

.alert-warn {
  --alert-bg-color: var(--alert-background-warn);
  --alert-border-color: var(--alert-border-color-warn);
  --alert-icon-path: url(../../images/icons/warning.svg);
}

.alert-error {
  --alert-bg-color: var(--alert-background-error);
  --alert-border-color: var(--alert-border-color-error);
  --alert-icon-path: url(../../images/icons/error.svg);
}
