.tabs[data-tabs-style*="pill"] {
  background-color: var(--panel-bg-color);
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  display: flex;
  font-size: 0.875rem;
  gap: 1px;
  text-transform: capitalize;
  width: fit-content;

  & > li {
    border-radius: var(--border-radius-medium);
    min-width: var(--spacing-20);
  }

  .tab {
    align-items: center;
    display: flex;
    gap: var(--spacing-2);
    justify-content: center;
    padding: var(--spacing-1) var(--spacing-3);
    text-decoration: none;
    z-index: 1;
  }

  .tab:not(aria-disabled="false") {
    cursor: pointer;
  }

  .tab[aria-selected="true"],
  .tab[aria-disabled="false"] {
    background-color: var(--bg-color);
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
    margin: -1px;
  }

  .label {
    aspect-ratio: 1 / 1;
    border: 0;
    font-size: 0.625rem;
    justify-content: center;
    height: var(--spacing-5);
    padding: 0;
  }

  .tab.before svg {
    margin-right: var(--spacing-2);
    transform: rotate(-180deg);
  }

  .tab.after svg {
    margin-left: var(--spacing-2);
  }

  .tab[aria-disabled="true"] {
    opacity: 0.2;
  }
}

.tabs[data-tabs-style*="nav"] > li {
  border-radius: var(--border-radius-medium);
  min-width: var(--spacing-20);

  &:first-child .tab {
    border-radius: var(--border-radius-medium) 0 0 var(--border-radius-medium);
  }

  &:last-child .tab {
    border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
  }
}

.dark-theme .tabs[data-tabs-style="pill"] {
  --panel-bg-color: var(--black);

  .tab[aria-selected="true"] {
    --bg-color: var(--bg-color-light);
  }
}

.tabs-menu {
  align-items: center;
  border-bottom: var(--border-default);
  display: flex;
  gap: var(--spacing-6);

  .tab {
    border-bottom: 2px solid var(--tab-border-color);
    border-radius: 0;
    color: var(--tab-text-color);
    cursor: pointer;
    display: inline-flex;
    height: auto;
    padding: var(--spacing-4) 0;
    position: relative;
    outline: none;
    overflow: hidden;
    text-decoration: none;
    z-index: 2;

    &[aria-current] {
      --tab-border-color: var(--base-color);
      --tab-text-color: var(--base-color);
    }
  }

  .extra {
    margin-inline-start: auto;
  }
}
