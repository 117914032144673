portal-resource-title {
  display: flex;
  align-items: center;

  a {
    color: var(--text-color);
    font-size: 1.25rem;
    display: inline-flex;
    align-items: center;
    height: 100%;
    text-decoration: none;

    > * {
      font-size: 1.25rem;
    }
  }

  img {
    max-height: var(--logo-height);
    max-width: var(--logo-width);

    &:is(.dark-theme *) {
      filter: invert(0.92) hue-rotate(180deg);
    }
  }

  @media (max-width: 850px) {
    height: 100%;
  }
}
