.label {
  --label-bg: var(--panel-bg-color);
  --label-bg-info: var(--bg-color-neutral);
  --label-bg-error: var(--bg-color-error);
  --label-bg-success: var(--bg-color-success);
  --label-bg-none: var(--bg-color-transparent);
  --label-border-color: var(--border-color-default);
  --label-text-color: var(--text-color);

  align-items: center;
  background-color: var(--label-bg);
  border-radius: var(--border-radius-medium);
  color: var(--label-text-color);
  display: inline-flex;
  font-size: 0.75rem;
  gap: var(--spacing-1);
  line-height: 2;
  padding: 0 var(--spacing-2);
  white-space: nowrap;

  svg {
    height: 13px;
  }
}

/* shape variants */
.label[data-label-type="rounded"] {
  --label-bg: var(--bg-color);
  --label-text-color: var(--app-text-color-secondary);

  border-radius: var(--border-radius-round);
  border: 1px solid var(--label-border-color);
  height: var(--spacing-5);
  line-height: 1;
  padding: var(--spacing-1) var(--spacing-2);
  text-transform: capitalize;
}

.label[data-label-type="outlined"] {
  background: 0;
  border: var(--border-default);
}

/* color modifiers */
.label[data-label-status="empty"] {
  --label-bg: var(--label-bg-none);
}

.label[data-label-status="info"] {
  --label-bg: var(--label-bg-info);
  --label-border-color: rgba(var(--blue-rgb), 0.1);
  --label-text-color: var(--text-color-neutral);
}

.label[data-label-status="success"] {
  --label-bg: var(--label-bg-success);
  --label-border-color: rgba(var(--green-rgb), 0.1);
  --label-text-color: var(--text-color-success);
}

.label[data-label-status="warning"] {
  --label-bg: var(--bg-color-warning);
  --label-border-color: rgba(var(--orange-50-rgb), 0.1);
  --label-text-color: var(--text-color-warning);
}

.label[data-label-status="error"] {
  --label-bg: var(--bg-color-error);
  --label-border-color: rgba(var(--red-rgb), 0.1);
  --label-text-color: var(--text-color-error);
}

.label[data-label-status="breaking"] {
  --label-bg: var(--label-bg-error);
  --label-border-color: rgba(var(--red-rgb), 0.1);
  --label-text-color: var(--text-color-error);
}

.label[data-label-status="inverted"] {
  --label-bg: var(--bg-color-inverted);
  --label-text-color: var(--text-color-inverted);
}

.label[data-label-status="extra"] {
  --label-bg: var(--bg-color-extra);
  --label-text-color: var(--text-color-extra);
}

.label[data-label-status="theme"] {
  --label-bg: color-mix(in srgb, var(--doc-base-color), transparent 90%);
  --label-text-color: var(--doc-base-color);
}
