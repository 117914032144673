.dropdown {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  transition: background-color var(--base-timing) var(--base-duration);
}

.dropdown.outlined:hover,
.dropdown--branches:hover {
  background-color: var(--bg-color-light);
}

.dropdown[aria-expanded="true"].outlined:hover,
.dropdown--branches[aria-expanded="true"]:hover {
  background-color: var(--bg-color);
}

.dropdown-title {
  --dropdown-title-min-width: initial;
  align-items: center;
  color: currentColor;
  cursor: pointer;
  display: flex;
  gap: var(--spacing-4);
  height: var(--spacing-8);
  justify-content: space-between;
  flex-direction: row;
  min-width: var(--dropdown-title-min-width);
  padding: var(--spacing-3) var(--spacing-8);
  transition: var(--theme-change-transition);
  white-space: nowrap;
}

.dropdown-title [data-dropdown-target="current"] {
  display: flex;
}

.dropdown.outlined .dropdown-title {
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-1) var(--spacing-4);
}

.dropdown.secondary .dropdown-title {
  background-color: var(--panel-bg-color);
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-2) var(--spacing-4);
}

.dropdown-title span {
  color: currentColor;
  display: block;
  font-size: 0.8rem;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-title .counter {
  border: var(--border-default);
  border-radius: var(--border-radius-round);
  color: var(--text-color-secondary);
  font-size: 0.75rem;
  margin-left: var(--spacing-2);
  padding: var(--spacing-px) var(--spacing-2) 0 var(--spacing-2);
}

.dropdown-title svg {
  color: currentColor;
  display: inline-block;
  flex-shrink: 0;
  opacity: 0.8;
  transform: rotate(0);
  transition: transform ease-in 0.2s;
}

.dropdown[aria-expanded="true"] .dropdown-title > .caret-down {
  transform: rotate(-180deg);
}

.menu-dropdown {
  background: var(--bg-color);
  border: var(--border-default);
  border-radius: var(--spacing-1);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  display: block;
  font-size: 0.875rem;
  max-height: none;
  min-width: 180px;
  opacity: 0;
  padding: var(--spacing-2);
  position: absolute;
  top: 200%;
  transition-property: opacity top;
  transition-duration: 200ms;
  visibility: hidden;
  width: max-content;
}

.menu-dropdown.without-padding {
  padding: 0;
}

.dropdown[aria-expanded="true"] .menu-dropdown {
  display: block;
  opacity: 100%;
  top: 140%;
  transition-duration: 0.5s;
  transition-property: all;
  transition-duration: 200ms;
  visibility: visible;
  z-index: 10;
}

.menu-dropdown li {
  margin: 0;
}

.menu-dropdown {
  z-index: 1;

  &.right {
    left: auto;
    right: 0;
  }

  a, button, [aria-current] {
    color: var(--text-color);
    display: flex;
    flex-direction: row;
    gap: var(--spacing-6);
    justify-content: space-between;
    padding: var(--spacing-2) var(--spacing-3);
    position: relative;
    width: 100%;
  }

  a, button {
    @apply flex flex-row items-center justify-between;
    background: transparent;
    border-radius: var(--border-radius-medium);
    text-decoration: none;

    &:hover {
      background-color: var(--dropdown-bg-color-hover);
      margin: 0;
      text-decoration: none;
    }

    &[data-button-state="warning"] {
      color: var(--text-color-warning);
    }

    &[data-button-state="error"] {
      color: var(--text-color-error);
    }
  }

  [aria-current] {
    cursor: default;
    padding-inline-end: var(--spacing-8);
  }
}

.menu-dropdown a.active::after {
  content: "";
  background: url("../../images/icons/checkmark.svg") no-repeat;
  filter: contrast(0);
  height: var(--spacing-3);
  width: var(--spacing-3);
}

.menu-dropdown .menu-item {
  align-items: center;
  display: flex;
  gap: var(--spacing-3);
}

.menu-dropdown > span {
  display: flex;
  padding: var(--spacing-3) var(--spacing-8);
}

.menu-dropdown .icon-link {
  align-items: center;
  justify-content: start;
}

.menu-dropdown svg {
  color: currentColor;
  margin-left: var(--spacing-3);
  position: absolute;
  transform: none;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.menu-dropdown a.active svg {
  display: block;
}

.menu-dropdown .icon-link svg {
  margin: 0;
  margin-right: var(--spacing-3);
  stroke: currentColor;
}

@media (max-width: 500px) {
  .menu-dropdown hr {
    border-color: var(--app-bg-color-hover);
    border-width: 1.5px;
    margin: 0 var(--spacing-6);
  }
}

@media (min-width: 500px) {
  .dropdown {
    padding: 0;
  }

  .dropdown-title {
    padding: var(--spacing-1) 0;
    user-select: none;
  }

  .dropdown-button .dropdown-title {
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
    column-gap: var(--spacing-1);
    display: flex;
    min-height: var(--spacing-8);
    padding: var(--spacing-1) var(--spacing-4);
  }

  .menu-dropdown > ul {
    display: grid;
    gap: var(--spacing-1);
    max-height: 50vh;
    overflow-y: auto;
  }

  .menu-dropdown li {
    margin: 0;
  }

  .dropdown.active .menu-dropdown a {
    color: var(--text-color);
  }

  .menu-dropdown > span {
    padding: var(--spacing-1) var(--spacing-3);
  }

  .menu-dropdown > :last-child {
    white-space: nowrap;
  }

  .menu-dropdown hr {
    margin: var(--spacing-1) var(--spacing-3);
  }
}

.dropdown--branches {
  border: var(--border-default);
  border-radius: var(--border-radius-round);
  margin: 0;
  min-width: var(--spacing-30);
  padding: var(--spacing-1) var(--spacing-4);
}

.dropdown--branches .dropdown-title {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0;
}

.dropdown--branches .menu-dropdown {
  left: 0;
}
