:root {
  --font-size: 16px;

  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --font-family: Avenir Next, -apple-system, system-ui, "Helvetica Neue",
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", sans-serif;
  --heading-font-family: var(--font-family);
  --code-font-family: "Roboto Mono", monospace;

  --text-line-height: 1.5;
  --title-line-height: 1.2;

  --text-xxs: 0.625rem;
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-base: 1rem;
  --text-l: 1.125rem;
  --text-xl: 1.25rem;
  --text-2xl: 1.5rem;
  --text-3xl: 2rem;
  --text-5xl: 3rem;
}

