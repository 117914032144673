.changelog {
  --dot-top: var(--spacing-16);
  --timeline-width: 160px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.changelog-menu {
  background-color: var(--panel-bg-color);
  border-block-end: var(--border-default);
  padding-block: var(--spacing-4);
  z-index: 5;
}

.changelog-menu__container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-3) var(--spacing-6);
  justify-content: space-between;
}

.changelog-menu__selector {
  align-items: center;
  display: grid;
  gap: 0 var(--spacing-2);
  grid-template-columns: 1fr auto auto;

  h1 {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: var(--font-weight-medium);
    margin: 0;
  }

  .version {
    font-size: var(--text-sm);
    grid-column: 1 / -1;
  }
}

.changelog-menu__last-update {
  background-color: transparent;
  border: var(--border-default);
  border-radius: var(--border-radius-round);
  flex-shrink: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: var(--spacing-1) var(--spacing-3);
}

.changelog-menu__actions {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  gap: var(--spacing-2);
}

.changelog-menu__actions .button {
  padding: var(--spacing-2) var(--spacing-3);
  transition: background-color 0.2s ease-out;
}

.changelog-menu__actions .button:hover,
.changelog-event__compare-button:hover {
  --button-color-hover: var(--slate-10);
  border-color: var(--border-color-default);
}

.changelog-content {
  flex-grow: 1;
  grid-template-rows: min-content auto;

  page-breadcrumbs {
    height: var(--spacing-16);
  }
}

.changelog-feed {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  width: 100%;

  .pagination {
    border-left: 2px solid var(--border-color-default);
    padding-block: var(--spacing-6) var(--spacing-10);
  }
}

.changelog-feed > :last-child {
  flex-grow: 1;
}

.changelog-section {
  flex-grow: 1;
}

.changelog-section:last-child {
  margin-bottom: 0;
  padding-bottom: var(--spacing-8);
}

.changelog-update {
  display: block;
  font-size: 0.875rem;
}

.changelog-event {
  display: grid;
  grid-template-columns: var(--spacing-40) auto;
  gap: var(--spacing-2) var(--spacing-30);
}

.changelog-event--single-column {
  grid-template-columns: 1fr;
}

.changelog-event.highlight-container.shared::after {
  top: var(--spacing-10);
  bottom: 0;
}

.changelog-event.highlight-container {
  scroll-margin-top: var(--spacing-24);
}

.changelog-event .dismiss-highlight {
  top: var(--spacing-12);
}

.changelog-event__date {
  align-items: start;
  border-right: 1px solid var(--border-color-default);
  box-sizing: content-box;
  color: var(--black-80);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding-block: var(--spacing-14) var(--spacing-20);
  padding-right: var(--spacing-10);
  position: relative;
  width: var(--timeline-width);

  .change-info {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }

  &:has(.back-link) {
    padding-top: var(--spacing-8);

    &::after {
      top: var(--spacing-16);
    }
  }

  .change-id {
    font-size: var(--text-sm);
  }
}

.changelog-event__date::after {
  background-color: var(--title-color);
  border-radius: 50%;
  content: "";
  display: block;
  height: var(--spacing-2);
  position: absolute;
  right: calc(var(--spacing-1-minus) - 0.5px);
  top: var(--dot-top);
  width: var(--spacing-2);
}

.changelog-event__date a.anchor-link:not(.button) {
  color: var(--title-color);
}

.changelog-event__date .change-labels {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2);
}

.changelog-event__date .change-labels .version,
.changelog-event__date .button {
  font-size: 0.875rem;
}

.changelog-event__date .button {
  --button-color-hover: var(--panel-bg-color);
  --button-border-color-hover: var(--border-color-default);
  flex: 0;
  line-height: 1;
  margin-top: var(--spacing-2);
}

.changelog-event__content-wrapper:hover .collapsed::after {
  background: linear-gradient(180deg, var(--transparent-bg-color) 0%, var(--bg-color) 75%);
}

.changelog-event__content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  margin-block: var(--spacing-14) var(--spacing-6);
}

.changelog-event__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-6);
  line-height: var(--spacing-8);
  row-gap: var(--spacing-2);

  .change-navigation {
    display: flex;
    flex-wrap: wrap;
    font-size: var(--text-sm);
    gap: var(--spacing-2) var(--spacing-4);
    line-height: 1;

    > * {
      align-items: center;
      display: flex;
      gap: var(--spacing-2);
    }

    span {
      color: var(--text-color-disabled);
    }

    .next svg {
      transform: rotate(180deg);
    }
  }
}

.changelog-event__title {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
}

.changelog-event__title h3 {
  color: var(--text-title);
  display: inherit;
  font-size: 1.25rem;
  font-weight: inherit;
  margin: 0;
}

.changelog-event__tags {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2);
}

.changelog-event__link {
  font-size: 0.875rem;
  margin: var(--spacing-6) auto;
}

.changelog-event__link a {
  border-radius: var(--border-radius-medium);
  color: var(--doc-base-color);
  position: relative;
  text-decoration: none !important;
  padding: var(--spacing-2);
  transition: background-color 0.2s ease-out;
}

.changelog-event__link a:hover {
  background-color: var(--doc-base-light-color);
}

.changelog-event__link .active,
.changelog-event__link .disabled {
  color: var(--title-color);
  position: relative;
  text-decoration: none !important;
  padding: var(--spacing-2);
}

.changelog-event__diff-container .changelog-event__diff:not(:first-child) {
  margin-top: var(--spacing-6);
}

.changelog-event__diff {
  border: var(--border-default);
  border-radius: var(--border-radius-large);
  overflow: hidden;
}

.changelog-event__diff-header {
  align-items: center;
  background-color: var(--panel-bg-color);
  border-bottom: var(--border-default);
  display: flex;
  gap: var(--spacing-2);
  padding: var(--spacing-3) var(--spacing-4);
}

.changelog-event__diff-header .counter {
  align-items: center;
  display: flex;
  background-color: var(--bg-color-secondary);
  border-radius: var(--border-radius-round);
  font-size: 0.75rem;
  justify-content: center;
  max-height: var(--spacing-5);
  min-width: var(--spacing-5);
  padding: var(--spacing-05) var(--spacing-1);
}

.changelog-event__diff-header .breaking {
  color: var(--text-color-error);
  font-size: 0.75rem;
}

.changelog-event__diff-lines li {
  line-height: 1.75;
  white-space: pre-wrap;
}

.changelog-event__diff > ul {
  display: grid;
  gap: var(--spacing-4);
}

.changelog-event__diff-operations {
  padding: var(--spacing-4);
}

.changelog-event__diff-operation {
  font-size: 1.125rem;
  padding: 0 var(--spacing-4);
}

.changelog-event__diff-operation:not(:first-of-type) {
  margin-top: var(--spacing-6);
}

.changelog-event__diff-properties:not(:first-of-type)::before {
  border-top: var(--border-default);
  content: "";
  display: block;
  margin-bottom: var(--spacing-6);
  margin-left: calc(var(--spacing-2) * -1);
  width: 100%;
}

.changelog-event__diff-properties .markdown-content p {
  color: var(--text-color);
  margin: 0;
  line-height: 2;
}

.changelog-event__diff-operation > code {
  margin-left: calc(var(--spacing-4) * -1);
}

.changelog-event__diff-properties {
  border-left: var(--border-default);
  font-size: 0.875rem;
  margin-top: var(--spacing-4);
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-6);
  padding-block: 0;
}

.changelog-event__diff-properties > li:first-child:not(.type) {
  border: 0;
  margin-left: calc(var(--spacing-2) * -1);
  padding: 0;
}

.changelog-event__diff-properties > li:not(.type),
.changelog-event__diff-properties ul {
  padding-left: var(--spacing-2);
}

.changelog-event__diff-properties li:not(.type) {
  border-left: var(--border-default);
  padding-left: var(--spacing-4);

  .detail-type {
    color: var(--text-color-secondary);
    font-size: 0.875rem;
  }
}

.changelog-event__diff-properties .type {
  color: var(--text-color);
  font-size: 1rem;
  margin-left: calc(var(--spacing-2) * -1);
  text-decoration: capitalize;
}

.changelog-event__diff-properties .markdown-content {
  align-items: start;
  display: flex;
  gap: var(--spacing-2);
}

.changelog-event__diff code.code-inline {
  color: currentColor;
  font-family: var(--font-family);
  font-size: 0.875rem;
  padding: var(--spacing-05) var(--spacing-1);
}

.changelog-event__diff-operation span {
  font-size: 0.75rem;
  margin-left: var(--spacing-1);
}

.changelog-event__diff-operation span.added {
  color: var(--text-color-success);
}

.changelog-event__diff-operation span.modified {
  color: var(--text-color-warning);
}

.changelog-event__diff-operation span.removed {
  color: var(--text-color-error);
}

.changelog-event__diff-operation .label {
  margin-left: var(--spacing-1);
}

.changelog-event__diff-operation ul {
  margin-top: var(--spacing-2);
}

.changelog-event__diff-operation ul > li:not(:last-of-type) {
  padding-bottom: var(--spacing-2);
}

.changelog-event__diff-operation li {
  line-height: 1.75rem;
}

.changelog-sidebar {
  @apply self-start;
  flex-basis: 33%;
  top: var(--spacing-10);
}

@media (min-width: 850px) {
  .changelog-sidebar {
    @apply sticky;
  }

  .changelog-feed .pagination {
    border-left: var(--border-default);
    margin-left: auto;
    width: calc(100% - var(--spacing-10) - var(--timeline-width));
  }
}

.changelog-branch {
  border-radius: var(--border-radius-medium);
  box-shadow: 0px 5px 24px rgba(19, 20, 21, 0.07);
  padding: var(--spacing-6);
}

@media (max-width: 850px) {
  .changelog-event {
    border-left: 2px solid var(--border-color-default);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6);
    padding-block: var(--spacing-6) 0;
    padding-left: var(--spacing-10);
  }

  .changelog-event__date,
  .changelog-event__content {
    border: 0;
    padding: 0;
  }

  .changelog-event__content {
    margin-block: 0;
    padding-bottom: var(--spacing-20);
    position: relative;
  }

  .change-navigation {
    bottom: 0;
    justify-content: space-between;
    margin-bottom:var(--spacing-10);
    position: absolute;
    width: 100%;
  }

  .changelog-event__date::after {
    left: calc(var(--spacing-10-minus) - 5px);
    top: var(--spacing-1);
  }

  .changelog-event__date {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
    padding-top: var(--spacing-12);
    width: 100%;

    &::after {
      top: var(--spacing-14);
    }

    .button {
      box-sizing: border-box;
      height: var(--spacing-8);
      margin: 0;
    }

    &:has(.back-link) {
      row-gap: var(--spacing-8);
      padding-top: 0;

      &::after {
        top: var(--spacing-14);
      }

      .back-link {
        grid-column: 1 / -1;
        grid-row: 1 / 1;
        top: var(--spacing-12-minus);
      }

      .button {
        grid-column: 2 / 2;
        grid-row: 2 / 3;
      }
    }
  }

  .changelog-feed {
    @apply flex-col;
  }

  .changelog-section {
    order: 1;
  }

  .changelog-sidebar {
    align-self: auto;
    order: 0;
  }
}
