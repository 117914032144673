[data-controller*="show-more"] {
  position: relative;
}

[data-controller*="show-more"].collapsed {
  overflow: hidden;
}

[data-controller*="show-more"].collapsed::after {
  background: linear-gradient(180deg, var(--transparent-bg-color) 0%, var(--bg-color) 75%);
  bottom: 0;
  content: "";
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
}

.expandable-properties-list:not(.active) .collapsed::after {
  background: linear-gradient(180deg, var(--transparent-bg-color) 0%, var(--list-bg-color) 75%);
}

[data-controller*="show-more"] .show-more-button {
  background-color: var(--panel-bg-color);
  border: var(--border-default);
  border-radius: 100px;
  bottom: var(--spacing-2);
  color: var(--text-color);
  cursor: pointer;
  height: auto;
  font-size: 0.875rem;
  left: 50%;
  padding: calc(var(--spacing-1) + var(--spacing-05)) var(--spacing-3);
  position: absolute;
  transform: translateX(-50%);
  transition: var(--theme-change-transition);
  z-index: 1;
}

[data-controller*="show-more"] .show-more-button:hover {
  background-color: var(--bg-color-secondary);
}
