.skeleton-line {
  background-color: var(--skeleton-color);
  border-radius: var(--spacing-40);
  display: block;
  height: var(--spacing-3);
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.skeleton-line::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  content: "";
}

.skeleton-line[data-length="xl"] {
  width: 90%;
}

.skeleton-line[data-length="l"] {
  width: 75%
}

.skeleton-line[data-length="m"] {
  width: 60%;
}

.skeleton-line[data-length="s"] {
  width: 45%;
}

.skeleton-line[data-length="xs"] {
  width: 30%;
}

.skeleton-line[data-length="heading"] {
  margin-bottom: var(--spacing-8);
  width: 40%;
}
