.use-case {
  @apply text-lg;
}

.use-case aside {
  @apply mt-3;
}

.use-case h2 {
  margin-bottom: var(--spacing-4);
}

.use-case h3 {
  @apply text-black text-lg font-semibold;
  margin-bottom: var(--spacing-2);
  margin-top: var(--spacing-12);
}

.use-case .link-arrow {
  @apply font-normal;
}

.use-case p,
.use-case img {
  &:not(:last-child) {
    margin-bottom: var(--spacing-8);
  }
}

.use-case .hero-image {
  @apply h-[391px] object-cover rounded-lg w-full md:w-[440px];
}

.use-case ul li {
  @apply mt-8;

  &::before {
    @apply h-2 w-2 bg-gradient-blue inline-block mr-4 rounded-full;
    content: "";
  }

  &:not(:first-child) {
    @apply mt-4;
  }
}

.use-case blockquote {
  @apply pl-12 my-12 font-semibold relative;
}

.use-case blockquote::before {
  @apply block absolute;
  background: url("../../images/misc/blockquote.svg");
  content: "";
  height: 20px;
  left: 0;
  top: 4px;
  width: 22px;
}

.use-case a {
  color: var(--base-color);
}
