.changelog-subscription__modal .label {
  font-size: 0.75rem;
  padding: var(--spacing-px) var(--spacing-3);
}

.changelog-subscription {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  width: 100%;
}

.changelog-subscription__description {
  font-size: 0.875rem;
  margin-bottom: var(--spacing-3);
}

.changelog-subscription__form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.changelog-subscription__input {
  display: flex;
  width: 100%;
}

.changelog-subscription__input input.email {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.changelog-subscription__input input.email,
.changelog-subscription__rss {
  width: 100%;
}

input.changelog-subscription__submit {
  border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
  padding: var(--spacing-2) var(--spacing-6);
}

p.changelog-subscription__notification {
  margin: var(--spacing-4) 0;
}
