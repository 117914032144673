search-input {
  display: grid;
  align-items: center;
  justify-content: start;

  button {
    grid-area: 1 / 1;
    width: var(--spacing-12);
  }

  svg {
    color: var(--text-color);
  }

  input {
    grid-area: 1 / 1;
    padding-inline-start: var(--spacing-11);
  }
}
