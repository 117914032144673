common-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);

  h1, p {
    margin: 0;
  }

  h1 {
    font-size: 1.5rem;
  }
}
