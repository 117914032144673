.preview {
  @apply flex flex-wrap h-auto items-center justify-between rounded-lg;

  &.bg-gradient {
    @apply bg-cover md:bg-auto;
    background: url("../../images/misc/dots-cta.svg") no-repeat center,
    url("../../images/misc/ellipse.svg") no-repeat center right,
    linear-gradient(-90deg, var(--sky-blue) 0.3%, var(--blue) 102.11%);
  }
  
  h2 {
    @apply text-white text-3xl md:w-3/6;
  }

  .tabs {
    @apply border-b border-lighterblue flex gap-3 text-lighterblue relative;
    width: max-content;

    .tab {
      @apply cursor-pointer inline-block pb-3 select-none;

      &[aria-selected="true"] {
        @apply text-white relative;

        &::after {
          @apply absolute bg-white -bottom-px left-0 right-0 w-full;
          content: "";
          height: 1.5px;
        }
      }
    }
  }

  mark {
    @apply bg-none bg-orange mr-1;
  }

  p {
    @apply leading-none mt-4 text-sm;
  }

  .preview-form {
    form {
      @apply flex mt-4;

      input[type="url"] {
        @apply h-12 px-4 rounded-r-none text-black text-base w-full;
      }

      input[type="submit"] {
        @apply cursor-pointer duration-200 h-auto px-4 rounded-l-none rounded-r transition w-40;
      }
    }
  }

  .preview-cli {
    @apply mt-4;

    .preview-cli-example {
      @apply bg-black flex h-12 items-center justify-between px-4 rounded text-base;

      &:not(:first-of-type) {
        @apply mt-2;
      }

      .copy-example {
        @apply cursor-pointer text-sm select-none;
      }
    }
  }

  .anchor {
    scroll-margin-top: calc(var(--spacing-16) * 4);
  }
}

.preview-inline .tabs {
  @apply border-gray-40;
}

.preview-inline .tabs .tab {
  @apply duration-200 text-gray-40 transition hover:text-gray-50;
}

.preview-inline .tabs .tab[aria-selected="true"] {
  @apply text-blue;
}

.preview-inline .tabs .tab[aria-selected="true"]::after {
  @apply bg-blue;
}

.preview-inline .preview-cli-example {
  @apply text-gray;
}
