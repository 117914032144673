body {
  @apply dark:bg-blue-100;
  background-color: var(--bg-color);
  position: relative;
}

mark {
  @apply bg-gradient-blue;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  text-decoration-line: none;
}

button {
  border: 0;
  padding: 0;
}
