.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.gap-4 {
  gap:var(--spacing-4);
}

.bg-dots {
  @apply relative;

  &::before {
    content: "";
    @apply absolute inset-0 pointer-events-none;
    background: url("../../images/misc/dots-gray.svg") no-repeat center;
    background-size: cover;
    z-index: -1;
  }

  &.bg-dots-white {
    &::before {
      @apply opacity-10 z-0;
    }
  }
}

.bg-gradient-gray {
  background: linear-gradient(270deg, var(--white) 46.56%, var(--gray-20) 100%);
}

.bg-gradient-blue {
  background: linear-gradient(90deg, var(--sky-blue) 0.3%, var(--blue) 102.11%);
}

.bg-gradient-dots {
  background: url("../../images/misc/dots-signup.svg") no-repeat center,
    linear-gradient(90deg, var(--sky-blue) 0.3%, var(--blue) 102.11%);
}

.bg-gradient-dots-inverted {
  background: url("../../images/misc/dots-signup.svg") no-repeat center,
    linear-gradient(90deg, var(--blue) 0.3%, var(--sky-blue) 102.11%);
}

.border-gradient-blue {
  border: solid 1px transparent;
  background-image:
    linear-gradient(90deg, #0977E3, #066ADC),
    radial-gradient(41.24% 227.33% at 63.93% 28.84%, rgba(255, 255, 255, 0.8) 9.38%, rgba(255, 255, 255, 0.24) 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.border-gradient-blue-90 {
  border: 1px solid transparent;
  background-image:
    linear-gradient(90deg, rgba(34, 44, 58, 1), rgba(34, 44, 58, 1)),
    linear-gradient(90deg, rgba(25, 174, 255, 0.2) 0.3%, rgba(0, 60, 139, 0.2) 32.11%, rgba(28, 169, 245, 0.2) 88.32%);
  background-clip: padding-box, border-box;
}

.bg-gradient-gray {
  background: linear-gradient(270deg, var(--white) 46.56%, var(--gray-20) 100%);
}

.border-gradient-blue-white {
  border: solid 1px transparent;
  background-image:
    linear-gradient(90deg, white, white),
    linear-gradient(90deg, rgba(25, 174, 255, 0.4) 0.3%, rgba(255, 255, 255, 0.2) 25.92%, rgba(1, 92, 213, 0.4) 46.89%, rgba(25, 174, 255, 0.4) 88.32%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.transform-center-y {
  transform: translateY(-50%);
  top: 50%;
}

.transform-center-x {
  transform: translateX(-50%);
  left: 50%;
}

.transform-center {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@responsive {
  .bg-blur {
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.7);
  }

  .clip-ellipsis-left {
    clip-path: url(#ellipsisLeft);
  }

  .clip-ellipsis-right {
    clip-path: url(#ellipsisRight);
  }

  .grid-cols-3-auto {
    grid-template-columns: repeat(3, 1fr);
  }
}

:is([aria-hidden="true"], [hidden]) {
  display: none !important;
}

.slide-track {
  animation: scroll 70s linear infinite;
}

.warning-text {
  color: var(--text-color-warning);
}
