.error {
  @apply text-red text-sm flex items-center mt-1;

  &::before {
    @apply mr-2 inline-block;
    content: "";
    background: url("../../images/misc/errors.svg") no-repeat center;
    width: 14px;
    height: 14px;
    margin-top: -2px;
  }
}

.error_notification {
  @apply text-red text-sm;
}

.field_with_errors {
  input {
    @apply border-red !important;
  }
}
