.upgrade-subscription-modal {
  header {
    text-transform: none;
  }
  .dialog-body {
    font-weight :500;

    button {
      width: fit-content !important;
      align-self: end;
    }
  }
}