.form-input {
  @apply flex flex-col h-12 border border-solid border-gray px-4 mt-1 w-full transition duration-200;

  &:valid {
    @apply border-black;
  }

  &:focus {
    @apply border-black;
  }
}

.radio {
  @apply text-sm;

  > label {
    @apply inline-flex items-center cursor-pointer;

    .radio_buttons {
      @apply h-4 w-4 min-w-4 mr-4;
    }
  }
}

