.list-checkmark {
  li {
    &:not(.no-check)::before {
      content: url("../../images/icons/checkmark-gray.svg");
      @apply mr-3;
    }

    &:not(:last-child) {
      @apply mb-3;
    }
  }
}

.list-dots {
  @apply pl-16;

  li {
    @apply relative;

    &:before {
      content: "";
      @apply inline-block absolute mb-px bg-gradient-blue rounded-full;
      height: 6px;
      width: 6px;
      top: 0.625rem;
      left: -1.25rem;
    }

    &:not(:last-child) {
      @apply mb-2;
    }
  }
}
