cookie-banner {
  align-items: center;
  background-color: var(--bg-color);
  border: var(--border-default);
  border-radius: var(--border-radius-round);
  bottom: var(--spacing-8);
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  gap: var(--spacing-2) var(--spacing-4);
  left: 0;
  margin: auto;
  max-inline-size: calc(100vw - var(--spacing-16));
  padding: var(--spacing-1-5);
  padding-left: var(--spacing-4);
  position: fixed;
  right: 0;
  width: fit-content;

  &[aria-hidden="true"] {
    display: none;
  }

  p {
    margin: 0;
  }

  a {
    color: var(--base-color);
    text-decoration: none;
  }

  .action-buttons {
    display: flex;
    gap: var(--spacing-2);
  }

  @media (width < 1000px) {
    border-radius: var(--border-radius-xlarge);
    padding: var(--spacing-3) var(--spacing-4);
  }
}
