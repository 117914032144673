.filters {
  border-bottom: var(--border-default);
  column-gap: var(--spacing-8);
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  row-gap: var(--spacing-8);
  width: 100%;
}

.filters li {
  align-items: start;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
}

.filters .filter__tab,
.filters .filter__tab:hover {
  border-bottom: 2px solid transparent;
  color: var(--text-color-secondary);
  column-gap: var(--spacing-2);
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--font-weight-semibold);
  padding-bottom: var(--spacing-3);
  text-decoration: none;
  white-space: nowrap;
}

.filters .filter__tab.active,
.filters .filter__tab.active:hover {
  border-color: var(--text-color);
  color: var(--text-color);
}

.filters .label {
  @apply inline-flex items-center;
  background-color: var(--bg-color-transparent);
  border: 1px solid var(--border-color-default);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 600;
  gap: var(--spacing-1);
  padding: var(--spacing-05) var(--spacing-2);
  text-transform: initial;
}

@media (max-width: 500px) {
  .filters {
    border: 0;
    flex-wrap: initial;
    font-size: 14px;
    overflow-x: scroll;
  }
}

.active-filter .filter-labels {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active-filter:not(:first-child) {
  margin-left: var(--spacing-3);
}

.active-filter.tabs:first-child {
  margin-right: auto;
}

filter-search {
  position: relative;
  gap: var(--spacing-3);

  label {
    font-weight: normal;
    margin: 0 var(--spacing-1) 0 0;
  }
  #filter_name{
    font-size: 0.9em;
  }
  button[type=submit] {
    background-color: var(--bg-color-transparent);
    color: var(--border-color-default);
    position: absolute;
    right: var(--spacing-3);
  }
}

.active-filters {
  display: flex;
  align-items: center;
  justify-content: end;

  @media (width < 800px) {
    justify-content: space-between;
  }
}
