.public-hero {
  margin: var(--spacing-20) auto var(--spacing-12);
  max-width: var(--screen-xl);
}

.public-hero .title {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: first baseline;
  margin-bottom: var(--spacing-4);
}

.public-hero .title h1 {
  font-size: 2rem;
  font-weight: var(--font-weight-semibold);
  margin-bottom: 0;
  margin-right: var(--spacing-3);
}
