.progress {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  height: 16px;
  width: 16px; 
}

.progress-circle {
  fill: none;
  stroke: var(--base-color);
  stroke-linecap: round;
  stroke-width: 4px;
}

.progress-range {
  stroke: var(--border-color-default);
}
