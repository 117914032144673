.testimonial-author {
  @apply flex items-center;

  .testimonial-author-image {
    @apply h-12 w-12;

    img {
      @apply rounded-full;
    }
  }

  .testimonial-author-text {
    @apply ml-4 text-sm;

    p {
      @apply leading-none;

      &:first-of-type {
        @apply font-semibold mb-2;
      }

      &:last-of-type {
        @apply opacity-80;
      }
    }
  }
}
