.pill {
  @apply border border-solid inline-block px-4 py-1 rounded-full text-xs;
}

.pill-blue {
  border-color: #9abfef30;
  background: #9abfef10;
  color: var(--blue-60);
}

.pill-dark {
  border-color: #9abfef30;
  background: cacl(var(--white) / 40);
  color: var(--gray-40);
}
