.empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacing-8);
  text-align: center;
}

.empty-state svg {
  margin-bottom: var(--spacing-8);
}

.empty-state .new,
.empty-state .new:hover {
  background: none;
  border: 0;
  color: var(--base-color);
  margin-top: var(--spacing-3);
  padding: 0;
  text-decoration: none;
}
