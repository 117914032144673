.list {
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  overflow: hidden;

  .list-head {
    background-color: var(--panel-bg-color);
    border-bottom: var(--border-default);
    font-size: 0.75rem;
    gap: var(--spacing-3);
    padding: var(--spacing-2) var(--spacing-4);
  }

  .list-item {
    align-items: center;
    gap: var(--spacing-3);
    padding: var(--spacing-4);
    transition: background-color var(--base-duration) var(--base-timing);
    text-decoration: none;

    .token {
      background-color: var(--bg-color-light);
      border-radius: var(--border-radius-small);
      padding: var(--spacing-1) var(--spacing-3);
      width: fit-content;
    }

    &:not(:last-of-type) {
      border-bottom: var(--border-default);
    }

    &:hover {
      background-color: var(--panel-bg-color);
    }
  }

  .list-empty {
    color: var(--panel-bg-color);
    display: grid;
    gap: var(--spacing-3);
    padding: var(--spacing-8);
    place-items: center;

    h2 {
      color: var(--text-color);
      margin: 0;
    }
  }
}

.list[data-list-cols="5"] .list-head,
.list[data-list-cols="5"] a.list-item {
  display: grid;
  grid-template-columns: 0.7fr 1fr 1fr 1fr 1fr 4px;
}

.list[data-list-cols="6"] .list-head,
.list[data-list-cols="6"] a.list-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr minmax(26px, 60px) 1fr 4px;
}
