.doc-compare__empty-state {
  align-items: center;
  background-color: var(--panel-bg-color);
  border-radius: var(--border-radius-large);
  border: var(--border-default);
  color: var(--panel-bg-color);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  justify-content: center;
  padding: var(--spacing-4) var(--spacing-6);
}

.doc-compare__empty-state__title {
  color: var(--text-color-secondary);
  font-size: 1.125rem;
  font-weight: var(--font-weight-medium);
  margin: 0;
}

.doc-compare__empty-state p {
  margin: 0;
}

.doc-compare__empty-state #version-loading path:last-child {
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: dash 1.2s linear infinite;
}
