radio-box {
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  .input-checked {
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    transition: border-color var(--base-duration) var(--base-timing);
    top: 0;

    &:hover {
      border-color: var(--input-border-color-hover);
    }
  }

  input:checked + .input-checked {
    box-shadow: rgb(2 95 215) 0px 0px 0px 2px inset;
  }

  label {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
    height: 100%;
    margin: 0;
    min-height: 93px;
    padding: var(--spacing-4);
    width: 100%;
  }

  .description {
    font-size: var(--text-xs);
    font-weight: var(--font-weight-medium);

    p {
      margin: 0;
    }
  }
}

.radio-box-container {
  display: grid;
  gap: var(--spacing-6);
  grid-template-columns: repeat(4, 1fr);
  max-width: var(--input-max-width);

  legend + & {
    margin-top: var(--spacing-2);
  }
}
