.blog-card {
  .image {
    @apply aspect-[2] rounded-lg overflow-hidden w-full;

    img {
      @apply h-full w-full object-cover;
    }
  }

  .text {
    @apply mt-6;

    .category {
      @apply bg-lightblue font-sans font-semibold py-1 px-4 rounded text-blue text-xs uppercase;
    }

    h2 {
      @apply mt-4;
    }

    .summary {
      @apply my-6 overflow-hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  &:not(.blog-card-highlight) {
    @apply mt-20;
  }
}

@screen md {
  .blog-card {
    @apply w-full;

    &.blog-card-highlight {
      @apply flex items-center w-full;

      .image {
        @apply mr-10 w-full;
      }

      .text {
        @apply mt-0 w-full;
      }
    }

    &:not(.blog-card-highlight) {
      @apply mt-0;

      .text {
        @apply mt-8;

        h2 {
          @apply text-2xl;
        }
      }
    }
  }
}
