quota-content {
  .quota-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .quota-icon-label {
    display: flex;
    align-items: center;
    
    svg {
      margin-right: var(--spacing-2);
    }
  }
  
  .quota-count {
    color: var(--text-color-secondary);
  }
  
  .progress-container {
    width: 100%;
    background-color: var(--bg-color-secondary);
    border-radius: var(--border-radius-round);
    height: var(--spacing-1);
    margin-top: var(--spacing-2);
  }
  
  .progress-bar {
    height: 100%;
    background-color: var(--slate-40);
    transition: width 0.5s;
    border-radius: var(--border-radius-round);
  }
}