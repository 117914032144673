/* purgecss start ignore */
@import "tailwindcss/components";
/* purgecss end ignore */

@import "tailwindcss/utilities";

@import "spacings.css";
@import "colors.css";
@import "variables.css";
@import "themes/dark/variables.css";
@import "abstracts/index.css";
@import "compositions/index.css";
@import "components/index.css";

* {
  scrollbar-width: thin;
}
