.pages-pricing {
  /* purgecss ignore current */
  @apply bg-lightgray;

  nav {
    @apply bg-lightgray;

    .btn-outline {
      &::after {
        @apply bg-lightgray;
      }
    }
  }

  .list-checkmark li:not(.no-check)::before {
    content: url("../../images/icons/checkmark-blue.svg");
  }

  section {
    .btn-outline {
      /* purgecss ignore current */
      @apply py-3;
    }
  }
}

@screen md {
  .pages-pricing {
    nav {
      backdrop-filter: blur(6px);
      background-color: rgba(249, 250, 251, 0.7);
    }
  }
}
