.users-sessions {
  .flash-alert {
    @apply flex items-center text-red text-sm mb-2;

    &::before {
      @apply h-3 w-3 mb-1/2 mr-2;
      content: "";
      background: url("../../images/icons/alert.svg") no-repeat;
    }
  }
}
