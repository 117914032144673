.back-link {
  --link-font-size: var(--text-xs);

  align-items: center;
  display: inline-flex;
  font-size: var(--link-font-size);
  gap: var(--spacing-1-5);
  text-decoration: none;

  svg {
    height: 9px;
    width: 9px;
  }

  &:where([data-link-size="large"]) {
    --link-font-size: var(--text-sm);

    svg {
      height: 11px;
      width: 11px;
    }
  }
}
