.unsubscribe {
  @apply min-h-screen px-8 pt-14 pb-16;

  .unsubscribe-container {
    @apply w-full mx-auto;
  }

  .unsubscribe-logo {
    @apply mx-auto;
  }

  .unsubscribe-title {
    @apply text-4xl mt-12;
  }

  .unsubscribe-text-w-link {
    @apply mt-4 mb-10;

    .unsubscribe-link {
      @apply font-sans font-semibold inline-block text-blue;
    }
  }
}

@screen md {
  .unsubscribe {
    @apply pb-0;

    .unsubscribe-container {
      @apply w-112;
    }
  }
}
