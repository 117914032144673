.loader[data-loader-type="circle"] {
  animation: rotation 1s linear infinite;
  aspect-ratio: 1 / 1;
  border: 2px solid var(--base-color);
  border-bottom-color: transparent;
  border-radius: 50%;
  height: var(--spacing-4);
}

.loader[data-loader-type="bars"] {
  --bar: no-repeat linear-gradient(currentColor 0 0);
  animation: load 1s infinite linear;
  aspect-ratio: 1;
  background:
    var(--bar) 0%   50%,
    var(--bar) 50%  50%,
    var(--bar) 100% 50%;
  background-size: 20% 100%;
  margin-right: 1px;
  width: 16px;
}
