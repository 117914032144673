.menu-toggle {
  background: 0;
  display: none;
  border: 0;
  padding: 0;
  position: relative;
  text-indent: -200vw;
  width: var(--spacing-6);

  &::before,
  &::after {
    content: "";
    display: block;
    border-top: 2px solid var(--text-color);
    border-radius: 2px;
    width: 100%;
    height: var(--spacing-05);
    position: absolute;
    transition: 0.25s ease-out transform;
    top: var(--spacing-2);
  }

  &::after {
    top: initial;
    bottom: var(--spacing-2);
  }
}

[data-mobile-nav-target="drawer"] {
  display: flex;
  transition: right 0.25s ease-in;
}

@media (max-width: 850px) {
  .menu-toggle {
    display: block;
    flex-shrink: 0;
  }

  [data-mobile-nav-target="drawer"] {
    align-items: stretch;
    background-color: var(--bg-color);
    border-right: var(--border-default);
    box-shadow: 0 2px 20px rgba(var(--black-rgb), 0.5);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    justify-content: space-between;
    left: -100%;
    max-width: calc(100vw - var(--spacing-18));
    padding: var(--spacing-10) 0;
    position: absolute;
    overflow-y: scroll;
    row-gap: var(--spacing-10);
    scrollbar-width: none;
    transition: left 0.35s ease-in;
    top: 0;
    will-change: left;
    width: var(--sidebar-width, 18.75rem);
    z-index: 100;
  }

  [data-toggled-menu="true"] {
    position: fixed;
    width: 100%;

    .menu-toggle {
      &::before {
        transform: translateY(7px) rotate(45deg);
        transition: 0.25s ease-in transform;
      }

      &::after {
        transform: translateY(-7px) rotate(-45deg);
        transition: 0.25s ease-in transform;
      }
    }

    [data-mobile-nav-target="drawer"] {
      left: 0;
    }
  }
}
