.pages-fastapi {
  @apply text-gray relative;

  &::before {
    @apply absolute h-full inset-0 w-full;
    content: "";
    background:
      url("../../images/misc/noise.png"),
      radial-gradient(37.35% 25.79% at 47.43% 25.79%, rgba(255, 133, 133, 0.8) 0%, rgba(2, 95, 215, 0) 100%),
      radial-gradient(50% 52.52% at 50% 37.73%, rgba(74, 20, 75, 0.5) 0%, rgba(2, 95, 215, 0) 100%),
      radial-gradient(22.01% 27.17% at 4.9% 15.89%, #4C50BB 0%, rgba(2, 95, 215, 0) 100%);
    opacity: 16%;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: -1;
  }

  main .link-arrow {
    color: var(--blue-40);
  }

  .feature-strate:nth-child(even) .image-container img,
  .feature-strate:nth-child(odd) .image-container img {
    @apply mx-auto;
  }
}
