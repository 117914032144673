summary {
  list-style-type: none;
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) div:focus,
body:not(.user-is-tabbing) li:focus,
body:not(.user-is-tabbing) summary:focus {
  outline: none;
}