.testimonial-card {
  @apply p-10 rounded-lg shadow-base text-lg;

  > img {
    @apply h-10;
  }

  > p {
    @apply min-h-18;
  }
}

@responsive {
  .testimonial-card-blue {
    @apply bg-gradient-dots-inverted text-white;

    > img {
      filter: brightness(5);
    }
  }

  .testimonial-card-white {
    @apply bg-white text-black dark:bg-blue-90 dark:text-gray;
  }

  @screen lg {
    .testimonial-card-blue {
      @apply text-2xl;
    }
  }

  @screen xl {
    .testimonial-card-blue {
      @apply w-100;
    }
  }
}

@screen md {
  .testimonial-card {
    > p {
      @apply min-h-41;
    }
  }
}
