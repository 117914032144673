@import "variables/typography.css";

:root {
  --base-color: var(--blue);

  --text-color: var(--gray-70);
  --text-color-disabled: var(--gray-40);
  --text-color-secondary: var(--gray-50);
  --text-color-inverted: var(--white);
  --title-color: var(--black);
  --title-color-secondary: var(--slate-50);
  --link-color-hover: var(--gray-90);
  --code-color: var(--text-color);
  --accent-color: var(--black);

  --bg-color: var(--white);
  --bg-color-rgb: var(--white-rgb);
  --bg-color-primary: var(--base-color);
  --bg-color-primary-light: var(--lighter-blue);
  --bg-color-secondary: var(--gray-20);
  --bg-color-light: var(--gray-10);
  --bg-color-transparent: hsla(0, 0%, 100%, 0.4);
  --bg-color-active: var(--lighter-blue);
  --bg-color-inverted: var(--blue-90);

  --border-color-default: var(--gray-20);
  --border-color-primary: var(--blue-20);
  --border-color-error: var(--red);
  --border-color-hover: var(--gray-50);
  --border-default: 1px solid var(--border-color-default);
  --border-primary: 1px solid var(--border-color-primary);

  --input-border-color-hover: var(--slate-70);
  --input-border-color-focus: var(--gray-40);
  --input-bg-color-disabled: var(--panel-bg-color);

  --hint-text-color: var(--slate-70);

  --text-color-success: var(--green-70);
  --text-color-warning: var(--orange-60);
  --text-color-error: var(--red);
  --text-color-neutral: var(--blue);
  --text-color-extra: var(--purple);

  --skeleton-color: var(--slate-20);

  --bg-color-success: var(--light-green);
  --bg-color-warning: var(--light-orange);
  --bg-color-error: var(--light-red);
  --bg-color-neutral: var(--lighter-blue);
  --bg-color-extra: var(--light-purple);

  --action-color: var(--gray-80);

  --panel-bg-color: var(--gray-10);
  --pill-bg-color: var(--slate-10);

  --dropdown-bg-color-hover: var(--slate-10);

  --border-radius-small: var(--spacing-05);
  --border-radius-medium: var(--spacing-1);
  --border-radius-large: var(--spacing-2);
  --border-radius-xlarge: var(--spacing-4);
  --border-radius-round: var(--spacing-10);

  --box-shadow-base: 0 1px 15px rgba(27, 31, 35, 0.15);
  --box-shadow-medium: 0px 5px 12px rgba(var(--black-rgb), 0.1);
  --box-shadow-large: 0px 5px 24px rgba(var(--black-rgb), 0.1);

  --screen-s: 500px;
  --screen-m: 850px;
  --screen-l: 1100px;
  --screen-xl: 1200px;

  --input-max-width: 720px;
  --input-box-shadow: inset 0 1px 2px rgba(var(--gray-40), 0.06);
  --input-box-shadow-focus: var(--input-box-shadow-focus),
    0 0 2px rgba(var(--gray-40), 0.9);

  --container-max-width: 1216px;

  --z-index-default: 0;

  /* Focus */
  --focus-outline-color: color-mix(in oklch, var(--action-color), transparent 40%);
  --focus-outline-width: 3px;
  --focus-outline: var(--focus-outline-width) solid var(--focus-outline-color);
  --focus-outline-offset: 2px;

  /* Animations */
  --base-duration: 150ms;
  --base-timing: ease;
  --theme-change-transition: background-color ease-out 0.1s;

  --theme-filter: sepia(20%) grayscale(10%);

  /* Callouts */
  --callout-background-info: var(--light-blue);
  --callout-background-success: var(--light-green);
  --callout-background-warn: var(--light-orange);
  --callout-background-error: var(--light-red);
  --callout-border-color-info: var(--dark-blue);
  --callout-border-color-success: var(--medium-green);
  --callout-border-color-warn: var(--bright-orange);
  --callout-border-color-error: var(--red);

  /* Alert */
  --alert-background-info: var(--light-blue);
  --alert-background-success: var(--light-green);
  --alert-background-warn: var(--light-orange);
  --alert-background-error: var(--light-red);
  --alert-border-color-info: var(--blue-rgb);
  --alert-border-color-success: var(--green-rgb);
  --alert-border-color-warn: var(--orange-rgb);
  --alert-border-color-error: var(--red-rgb);

  /* Navigation */
  --navigation-height: var(--spacing-16);

  /* Codeblock  */
  --bg-code-title: var(--black);

  /* Show more */
  --transparent-bg-color: rgba(255, 255, 255, 0);

  /* Date-time picker indicator color */
  --date-time-indicator-color: invert(0%) sepia(0%) saturate(0%) brightness(100%) contrast(100%);
}
