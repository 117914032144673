.toggle-darkmode {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.toggle-darkmode + .toggle-label {
  background-color: var(--slate-40);
  border-radius: 1em;
  position: relative;
  height: var(--spacing-3);
  width: var(--spacing-10);
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: -9999px;
  color: transparent;
  cursor: pointer;
  margin-block: var(--spacing-2);
  user-select: none;
}

.toggle-darkmode + .toggle-label::before,
.toggle-darkmode + .toggle-label::after {
  content: "";
  position: absolute;
  left: 0;
  transition: var(--theme-change-transition), left ease-out 0.4s;
}

.toggle-darkmode + .toggle-label::before {
  background-color: var(--bg-color);
  border: var(--border-default);
  border-radius: 50%;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  height: var(--spacing-6);
  width: var(--spacing-6);
  border: var(--border-default);
  border-radius: 50%;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
  height: var(--spacing-6);
  width: var(--spacing-6);
}

.toggle-darkmode + .toggle-label::after {
  background-color: var(--text-color);
  border-radius: 50%;
  box-shadow: 7px 0 0 -3px var(--text-color), -7px 0 0 -3px var(--text-color),
    0 7px 0 -3px var(--text-color), 0 -7px 0 -3px var(--text-color),
    5px -5px 0 -3px var(--text-color), -5px -5px 0 -3px var(--text-color),
    -5px 5px 0 -3px var(--text-color), 5px 5px 0 -3px var(--text-color);
  height: var(--spacing-2);
  width: var(--spacing-2);
  margin-left: var(--spacing-2);
  top: 2px;
}

.toggle-darkmode:checked + .toggle-label {
  background-color: var(--slate-80);
}

.toggle-darkmode:checked + .toggle-label::before,
.toggle-darkmode:checked + .toggle-label::after {
  left: 50%;
}

.toggle-darkmode:checked + .toggle-label::before {
  background-color: var(--bg-color);
  transform: rotate(360deg);
}

.toggle-darkmode:checked + .toggle-label::after {
  background-color: transparent;
  border: 0;
  box-shadow: -5px 3px 0px 0px var(--base-color);
  height: var(--spacing-4);
  width: var(--spacing-4);
  top: -5px;
  margin-left: calc(var(--spacing-2) + 2px);
}

.toggle-darkmode:checked + .toggle-label::after {
  animation: daynight 0.7s;
}

.toggle-darkmode:not(:checked) + .toggle-label::after {
  animation: nightday 0.7s;
}

@keyframes daynight {
  0% {
    background-color: transparent;
    transform: rotate(0deg);
    margin-left: var(--spacing-2);
    height: var(--spacing-2);
    width: var(--spacing-2);
    top: 2px;
    box-shadow: 7px 0 0 -3px var(--text-color), -7px 0 0 -3px var(--text-color),
      0 7px 0 -3px var(--text-color), 0 -7px 0 -3px var(--text-color),
      5px -5px 0 -3px var(--text-color), -5px -5px 0 -3px var(--text-color),
      -5px 5px 0 -3px var(--text-color), 5px 5px 0 -3px var(--text-color);
  }
  40% {
    transform: rotate(180deg);
    border-width: 3px;
    background-color: transparent;
    box-shadow: none;
    height: var(--spacing-2);
    width: var(--spacing-2);
    top: 2px;
  }
  60% {
    transform: rotate(360deg);
    border-width: 0;
    top: -5px;
    box-shadow: -5px 3px 0px 0px var(--doc-base-color);
    height: var(--spacing-4);
    width: var(--spacing-4);
  }
  100% {
    transform: rotate(360deg);
    height: var(--spacing-4);
    width: var(--spacing-4);
    top: -5px;
  }
}

@keyframes nightday {
  0% {
    height: var(--spacing-4);
    width: var(--spacing-4);
    top: -5px;
    transform: rotate(360deg);
    box-shadow: -5px 3px 0px 0px var(--doc-base-color);
    background-color: transparent;
    border: 0;
  }
  20% {
    height: var(--spacing-2);
    width: var(--spacing-2);
    top: 2px;
    box-shadow: -1px 1px 0px 0px var(--doc-base-color);
    background-color: transparent;
    border: 0;
    transform: rotate(360deg);
  }
  30% {
    height: var(--spacing-2);
    width: var(--spacing-2);
    top: 2px;
    box-shadow: none;
    background-color: transparent;
    border: 0;
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
    margin-left: var(--spacing-2);
    height: var(--spacing-2);
    width: var(--spacing-2);
    top: 2px;
    box-shadow: 7px 0 0 -3px var(--text-color), -7px 0 0 -3px var(--text-color),
      0 7px 0 -3px var(--text-color), 0 -7px 0 -3px var(--text-color),
      5px -5px 0 -3px var(--text-color), -5px -5px 0 -3px var(--text-color),
      -5px 5px 0 -3px var(--text-color), 5px 5px 0 -3px var(--text-color);
  }
}
