.container {
  @apply px-8 pb-30 mt-16 max-w-lg-screen mx-auto
}

@screen md {
  .container {
    @apply mt-20;
  }
}

@screen lg {
  .container {
    @apply px-20;
  }
}

@screen xl {
  .container {
    @apply px-40;
  }
}

.wrapper {
  --wrapper-width: var(--screen-xl);
  display: grid;
  grid-template-columns: 1fr min(var(--wrapper-width), calc(100% - 64px)) 1fr;
  grid-column-gap: var(--spacing-8);
  justify-content: center;
}

.wrapper > * {
  grid-column: 2;
}

.full-bleed {
  grid-column: 1 / -1;
}

.page-container {
  align-items: start;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: var(--screen-xl);
  padding-top: var(--spacing-16);
}

page-breadcrumbs {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.125rem;
  column-gap: var(--spacing-3);

  a {
    flex-shrink: 0;
    text-decoration: none;

    span {
      color: var(--text-color-secondary);
    }
  }

  .separator {
    color: var(--border-color-default);
  }

  .dropdown-button .dropdown-title {
    border: 0;
    padding-inline: 0;
  }

  .menu-dropdown a {
    box-sizing: content-box;
    display: block;
    padding-right: var(--spacing-10);
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: var(--spacing-68);
  }
}
