.pages-not_found {
  @apply flex flex-col justify-between mx-0;
  font-family: var(--font-family);
  min-height: 100vh;
}

.pages-not_found nav,
.pages-not_found footer {
  width: 100%;
}

.pages-not_found main {
  @apply flex flex-col items-center px-8;
  flex-grow: 1;
  padding: var(--spacing-40) var(--spacing-8);
  row-gap: var(--spacing-7);
}

.pages-not_found h1 {
  display: inline-block;
}

.pages-not_found h1 svg {
  width: 100%;
}

.pages-not_found .message {
  color: var(--text-color);
}
