.pages-legal {
  h2 {
    @apply mt-16;
  }

  h3 {
    @apply mt-8 text-lg;
  }

  p,
  li {
    @apply leading-relaxed mt-4 text-lg;
  }

  ol,
  table {
    @apply mt-6;
  }

  table table {
    @apply border-0 my-0;
  }

  table table td {
    @apply border-0 pt-0;
  }

  table table td:first-child {
    @apply pl-0;
  }

  table table td:last-child {
    @apply pr-0;
  }

  table li {
    @apply text-sm;
  }

  table li:first-child {
    @apply mt-0;
  }

  table ul > li::before {
    @apply top-1.5;
  }

  ol {
    list-style: inside number;
  }

  ul > li {
    @apply pl-6 relative;
  }

  ul > li::before {
    content: "";
    @apply absolute bg-gray-40 h-1.5 left-[3px] rounded-full top-2.5 w-1.5;
  }

  strong {
    @apply font-semibold text-black;
  }

  a {
    @apply font-semibold text-blue;
  }
}
