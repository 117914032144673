.form {
  @apply min-h-screen px-8 pt-14 pb-16;

  .form-container {
    @apply w-full mx-auto;
  }

  .form-logo-container {
    display: flex;
    justify-content: center;
    height: var(--spacing-14);
  }

  .form-logo img {
    height: 100%;
    margin-inline: auto;
  }

  .form-title {
    @apply text-4xl mt-12;
  }

  .form-text-w-link {
    @apply mt-4 mb-10;

    .form-link {
      @apply font-sans font-semibold inline-block text-blue;
    }
  }
}

@screen md {
  .form {
    @apply pb-0;

    .form-container {
      @apply w-100;
    }
  }
}
