
body.no-scroll {
  overflow: hidden /* avoid scroll when open dialog modal */
}

:where(dialog) {
  --dialog-width: 480px;

  background-color: var(--bg-color);
  background-image: linear-gradient(to bottom, var(--bg-color-secondary) 0%, transparent 100%);
  background-size: 100% var(--spacing-35);
  background-repeat: no-repeat;
  border: var(--border-default);
  border-radius: var(--border-radius-large);
  color: var(--text-color);
  max-width: var(--dialog-width);
  padding: 0;
  text-align: initial;
  white-space: wrap;
  width: 100%;

  &[data-dialog-size="large"] {
    --dialog-width: 640px;
  }

  &::backdrop {
    background: color-mix(in srgb, var(--black), transparent 70%);
  }

  .close-dialog {
    background: none;
    color: var(--text-color-secondary);
    height: var(--spacing-3);
    position: absolute;
    right: var(--spacing-6);
    top: var(--spacing-5);
  }

  header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6);
    margin: auto;
    padding: var(--spacing-4) var(--spacing-6) 0;
    width: calc(100% - var(--spacing-34));

    h2 {
      color: var(--title-color);
      font-size: var(--text-xl);
      font-weight: var(--font-weight-demibold);
      margin: 0;
      text-align: center;
    }
  }

  .dialog-body {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
    font-size: var(--text-sm);
    padding: var(--spacing-6);

    ul {
      list-style-type: disc;
      padding-left: var(--spacing-4);
    }

    hr {
      margin: 0 var(--spacing-6-minus);
    }

    h3 {
      color: var(--title-color);
      font-size: var(--text-sm);
      font-weight: var(--font-weight-semibold);
      margin-bottom: 0;
    }
  }

  .dialog-buttons {
    display: flex;
    gap: var(--spacing-3);
    justify-content: end;
    padding-top: var(--spacing-3);
  }

  &.confirmation-dialog {
    background-image: none;

    header {
      align-items: center;
      border-bottom: var(--border-default);
      flex-direction: row;
      justify-content: space-between;
      padding: var(--spacing-3) var(--spacing-6);
      width: auto;

      h2 {
        font-size: var(--text-sm);
        font-weight: var(--font-weight-semibold);
      }

      button {
        position: initial;

        svg {
          aspect-ratio: 1 / 1;
          width: var(--spacing-3);
        }
      }
    }

    .dialog-body {
      gap: 0;
      padding-block: var(--spacing-4);

      & > *:first-child {
        margin-top: 0;
      }

      .input {
        margin-top: var(--spacing-4);
      }

      .button:not(.dialog-buttons > *) {
        margin-top: var(--spacing-3);
        width: 100%;
      }
    }
  }
}
