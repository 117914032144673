.list-element {
  border: 1px solid var(--border-color-default);
  column-gap: var(--spacing-4);
  flex-wrap: wrap;
  margin-bottom: var(--spacing-3);
  border-radius: var(--border-radius-medium);
  display: flex;
  min-height: var(--spacing-20);
  padding: var(--spacing-6);
  row-gap: var(--spacing-4);
  align-items: stretch;

  &.disabled {
    background-color: var(--panel-bg-color);
    color: var(--app-text-color-secondary);
  }
}

.list-element .content {
  flex: 1;

  p {
    margin: 0;
  }
}

.list-element .header,
.list-element .tags {
  align-items: center;
  column-gap: var(--spacing-3);
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--spacing-2);
}

.list-element .tag {
  align-items: center;
  background-color: var(--doc-base-color, var(--bg-color-primary));
  border-radius: var(--border-radius-medium);
  color: var(--white);
  column-gap: var(--spacing-2);
  display: flex;
  font-size: 0.75rem;
  padding: 0 var(--spacing-2);
}

.list-element .tag svg {
  height: 1rem;
}

.list-element .title {
  align-items: center;
  display: flex;
  font-weight: var(--font-weight-medium);
  font-size: 1.1em;
  margin: 0;
}

.list-element .title .pill {
  background: var(--pill-bg-color);
  border-radius: 12px;
  font-size: 0.75em;
  margin-left: var(--spacing-2);
  padding: var(--spacing-1) var(--spacing-2);
}

.list-element .title .pill-owner {
  background:var(--bg-color-primary);
  color: var(--text-color-inverted);
}

.list-element .title .pill-ongoing {
  background:var(--bg-color-secondary);
  color: var(--text-color-secondary);
}

.list-element .actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2);
}

p {
  margin-bottom: 0;
}

.list-element .extra p {
  margin-bottom: 0;
}

.list-element .extra {
  display: flex;
  align-items: center;
}

.list-element .dropdown {
  font-size: 14px;
}

.list-element .button[data-button-style="secondary"] {
  border: 1px solid var(--border-color-default);
  color: currentColor;
  font-weight: var(--font-weight-medium);
  padding: var(--spacing-3);

  &:hover {
    background-color: transparent;
    color: currentColor;
  }

  &.delete {
    border-color: var(--border-color-default);
    color: var(--button-color-error);
  }

  &.delete:hover {
    border-color: var(--button-color-error);
  }
}

.list-element .delete {
  color: var(--red) !important;
}

@media (max-width: 500px) {
  .list-element {
    flex-direction: column;
  }

  .list-element .extra {
    margin-top: var(--spacing-3);
  }
}
