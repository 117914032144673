.pages-compare {
  @apply bg-blue-100;

  [data-slider-target="item"].active {
    @apply bg-blue-10 border border-blue/20 border-solid text-blue-70;
  }

  .list-check {
    @apply pl-4;

    li {
      @apply leading-relaxed pl-3;
      list-style-image: url("images/website/icons/checkmark-white.svg");

      &:not(:last-child) {
        @apply mb-4;
      }
    }
  }
}
