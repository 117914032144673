#loader {
  align-self: center;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

#loader:before,
#loader:after {
  content: "";
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: var(--white);
}

#loader:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#loader:after {
  border: 2px solid #0a78e3;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
