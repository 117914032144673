.dark-theme {
  --text-color: var(--gray-20);
  --text-color-secondary: var(--gray-40);
  --text-color-inverted: var(--black);
  --text-color-disabled: var(--slate-80);

  --title-color: var(--white);
  --link-color-hover: var(--gray-40);
  --accent-color: var(--white);

  --skeleton-color: var(--slate-80);

  --bg-color: var(--black);
  --bg-color-rgb: var(--black-rgb);
  --bg-color-secondary: var(--gray-80);
  --bg-color-light: var(--gray-90);
  --bg-color-transparent: rgba(var(--black), 0.5);
  --bg-color-active: var(--dark-blue);
  --bg-color-primary: var(--white);
  --bg-color-primary-light: var(--blue-100);
  --bg-color-inverted: var(--white);

  --border-color-default: var(--slate-80);
  --border-color-hover: var(--slate-20);
  --border-color-primary: var(--base-color);

  --hint-text-color: var(--slate-50);

  --bg-color-success: var(--dark-green);
  --bg-color-warning: var(--dark-orange);
  --bg-color-error: var(--darker-red);
  --bg-color-neutral: var(--darkest-blue);

  --action-color: var(--white);
  --panel-bg-color: var(--slate-90);
  --pill-bg-color: var(--slate-80);

  --dropdown-bg-color-hover: var(--slate-80);

  /* Callouts */
  --callout-background-info: var(--gray-90);
  --callout-background-success: var(--gray-90);
  --callout-background-warn: var(--gray-90);
  --callout-background-error: var(--gray-90);

  /* Alerts */
  --alert-background-info: var(--gray-90);
  --alert-background-success: var(--gray-90);
  --alert-background-warn: var(--gray-90);
  --alert-background-error: var(--gray-90);

  /* Codeblock  */
  --bg-code-title: var(--gray-90);

   /* Date-time picker indicator color */
   --date-time-indicator-color: invert(100%) sepia(0%) saturate(0%) brightness(100%) contrast(100%);
}
