.pagination {
  font-size: var(--text-sm);

  nav, .page {
    align-items: center;
    display: flex;
  }

  nav {
    gap: var(--spacing-4);
    padding-block: var(--spacing-1-5);
    place-content: end;
  }

  .page {
    gap: var(--spacing-2);
  }

  .prev svg {
    rotate: -90deg;
  }

  .next svg {
    rotate: 90deg;
  }

  .disabled {
    color: var(--text-color-disabled);
  }
}
