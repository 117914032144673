.accordion {
  details {
    &:not(:last-child) {
      @apply border-b border-solid border-gray;
    }

    p {
      @apply pb-8 leading-normal;
    }

    &:not([open]) {
      summary {
        &:hover {
          @apply opacity-75;
        }
      }
    }
  }

  summary {
    @apply relative text-lg font-sans font-semibold pr-32 select-none cursor-pointer;

    &:not(:last-child) {
      @apply py-8;
    }

    &::after {
      content: "";
      background: url("../../images/icons/plus.svg") no-repeat center;
      @apply absolute right-0 transform-center-y w-3 h-3 bg-cover;
    }
  }

  details[open] summary:after {
    background: url("../../images/icons/minus.svg") no-repeat center;
  }
}
