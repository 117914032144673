.list {
  @apply border-0 bg-lightgray dark:bg-transparent;
}

.list-container {
  @apply px-8 py-20 max-w-lg-screen mx-auto flex flex-col items-center;
}

.list ul {
  @apply flex flex-col;
  margin-top: var(--spacing-14);
  max-width: 45rem;
  row-gap: var(--spacing-10);
}

.list li {
  @apply flex items-start dark:text-gray;
  column-gap: var(--spacing-6);
  font-size: 18px;
  list-style-type: none;
}

.list li::before {
  background-image: url("images/website/icons/arrow-bullet.svg");
  content: "";
  display: inline-block;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}

@screen md {
  .list .header {
    @apply text-center;
  }
}
