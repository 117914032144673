.banner {
  align-items: start;
  background-color: var(--bg-color-light);
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: var(--spacing-6);
  padding: var(--spacing-3) var(--spacing-4);
}

.banner--transparent {
  background-color: transparent;
}

.banner__icon {
  flex-shrink: 0;
  width: 15px;
}

.banner__content,
.banner__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-2);
}

.banner__header {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-3);
  justify-content: space-between;
}

.banner__header-extra {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-size: 0.875rem;
  gap: var(--spacing-2);
  justify-content: end;
}

.banner__title {
  font-size: 1em;
  font-weight: var(--font-weight-semibold);
  margin-bottom: 0;
}

.banner__buttons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-3);
  margin-top: var(--spacing-2);
}

.banner--big {
  align-items: center;
  border-radius: var(--border-radius-large);
  font-size: 16px;
}

.banner--big .banner__title {
  font-size: 1.1175em;
}

.banner--big .banner__icon {
  width: 64px;
}

.banner--big .banner__content {
  gap: var(--spacing-1);
}
