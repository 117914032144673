.team-mate {
  @apply grid grid-cols-1 gap-6 mt-20 md:gap-12 md:grid-cols-2;

  .description {
    @apply self-center;

    .fullname {
      @apply font-sans font-semibold text-black text-xl;
    }
  }

  .card {
    @apply shadow-base rounded-lg bg-gradient-dots h-75 overflow-hidden;

    img {
      @apply h-full object-cover opacity-80 md:max-w-[262px];
    }
  }
}

.jobs {
  @apply lg:px-40;

  hr {
    @apply border-gray mt-4;
  }
}
