copy-field {
  align-items: center;
  background-color: var(--panel-bg-color);
  border-radius: var(--border-radius-medium);
  display: flex;
  gap: var(--spacing-3);
  justify-content: space-between;
  padding: var(--spacing-3);

  button[data-button-style="naked"] {
    color: var(--text-color);

    &:hover {
      text-decoration: none;

      &[disabled] {
        cursor: auto;
      }
    }

    svg {
      height: auto;
      width: auto;
    }
  }
}
